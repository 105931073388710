:root{
  --font-family: 'Noto Sans', sans-serif;
}

.ui.secondary.vertical.pointing.menu, button, input, optgroup, select, textarea, .ui.menu, .ui.header, .ui.dropdown,
.ui.input input, h1, h2, h3, h4, h5, .ui.button, .ui.accordion .title:not(.ui), .ui.modal>.header, body, .rrt-confirm-holder .rrt-confirm .rrt-message, .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url],
.redux-toastr .toastr .rrt-middle-container {
    font-family: var(--font-family);
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin:0;
  padding:0;
}